import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    InternalAxiosRequestConfig,
    AxiosResponse,
} from 'axios';

let baseURL = ""
if (process.env.NODE_ENV == "development") {
    baseURL = '/user-server'
} else {
    baseURL = 'https://dev.mdcrm.58victory.com'// 测试环境
    // baseURL = 'https://mdcrm.3s-md.com'// 正式环境
}

// 创建 Axios 实例
const service: AxiosInstance = axios.create({
    baseURL,
    timeout: 10000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // 在这里可以添加请求头，比如添加 token
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // 请求错误处理
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (response: AxiosResponse) => {
        // 响应数据处理
        const { data } = response;
        console.log(data);
        if (data?.head.st !== 0) {
            console.error(data.head.msg || '请求出错');
            return Promise.reject(data);
        }
        return data;
    },
    (error) => {
        // 响应错误处理
        console.error('响应错误:', error.response?.data || error.message);
        return Promise.reject(error);
    }
);

export default service;
